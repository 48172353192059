import { render, staticRenderFns } from "./copyright.vue?vue&type=template&id=6ed514af&scoped=true"
import script from "./copyright.vue?vue&type=script&lang=js"
export * from "./copyright.vue?vue&type=script&lang=js"
import style0 from "./copyright.vue?vue&type=style&index=0&id=6ed514af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ed514af",
  null
  
)

export default component.exports